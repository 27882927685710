import { isServiceWorkerAvailable } from '@/utils/is-service-worker-available.ts';
import { traceError } from '@/utils/trace-error.ts';
import { ApolloProvider } from '@apollo/client';
import { shutdown as shutdownIntercom } from '@intercom/messenger-js-sdk';
import { RemixBrowser } from '@remix-run/react';
import { Hub } from 'aws-amplify/utils';
import mixpanel from 'mixpanel-browser';
import { startTransition } from 'react';
import { hydrateRoot } from 'react-dom/client';
import { amplifyConfigure } from './amplify-configure.ts';
import { APP_URL } from './env.ts';
import { handleImportError } from './handle-import-error.ts';
import { invalidateSession } from './routes-utils/handle-authorized-data.ts';
import { initSentry } from './sentry.client.ts';
import { getApolloClient } from './apollo/apollo-client.ts';
import { setUser } from '@sentry/remix';

mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, {
  api_host: APP_URL,
  api_routes: {
    track: 'mp/t',
    engage: 'mp/e',
    groups: 'mp/g',
  },
  track_pageview: true,
  persistence: 'localStorage',
});

amplifyConfigure();

if (__WITH_SENTRY__) {
  initSentry();
}

if (isServiceWorkerAvailable()) {
  navigator.serviceWorker
    .register('/sw.js', { scope: '/' })
    .catch((err) =>
      traceError(
        new Error('Service Worker registration failed', { cause: err }),
      ),
    );
}

Hub.listen('auth', async ({ payload }) => {
  invalidateSession();
  if (payload.event === 'signedOut') {
    mixpanel.reset();
    shutdownIntercom();
    setUser(null);
    await getApolloClient().clearStore();
  }
});

handleImportError();

startTransition(() => {
  hydrateRoot(
    document,
    <ApolloProvider client={getApolloClient()}>
      <RemixBrowser />
    </ApolloProvider>,
  );
});
